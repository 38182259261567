<template>
  <div class="bg-white rounded-md shadow-md">
    <div
      @click="open = !open"
      class="p-5 rounded-md flex items-center cursor-pointer"
      :class="{
        'bg-amber-300': open,
        'hover:bg-blueGray-50': !open,
      }"
    >
      <div class="w-14 flex-shrink-0">
        <Icon name="Down" :class="{ 'transform rotate-180': open }" />
      </div>
      <p>
        <slot name="title" />
      </p>
    </div>
    <div class="p-5" v-show="open">
      <slot />
    </div>
  </div>
</template>

<script>
import Icon from "./icon/Icon.vue";
export default {
  components: { Icon },
  data() {
    return {
      open: false,
    };
  },
};
</script>
