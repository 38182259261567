<template>
  <BodySimple>
    <template #header>
      <h2 class="text-xl font-bold">سوالات متداول خدمات و تبلیغات در تلگرام</h2>
      <ul class="list-inside list-disc mt-5">
        <li>
          <router-link :to="{ hash: '#group_ad' }" class="text-sky-500">
            سوالات متداول تبلیغات
          </router-link>
        </li>
        <li>
          <router-link :to="{ hash: '#group_bot' }" class="text-sky-500">
            سوالات متداول ربات گروه
          </router-link>
        </li>
        <li>
          <router-link :to="{ hash: '#channel_bot' }" class="text-sky-500">
            سوالات متداول ربات کانال
          </router-link>
        </li>
      </ul>
    </template>
    <template #default>
      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md"
        id="group_ad"
      >
        <div class="text-lg font-bold flex items-center gap-2">
          <img src="@/assets/images/business-startup.png" class="inline-block h-20" />
          <h2 class="text-lg font-bold">سوالات متداول در مورد تبلیغات</h2>
        </div>

        <CommonQuestionCard class="mt-5">
          <template #title>تبلیغات سراسری گروه ها به چه صورت انجام می‌شود؟</template>

          تبلیغات توسط ربات مدیر گروه در بیش از 2000 گروه به دو صورت کلید شیشه‌ای و ارسال
          بنر انجام می‌شود و از بازدهی خوبی برخوردار است. رباتی که تبلیغات را ارسال می‌کند
          در گروه های متنوعی از بیشتر شهر‌ها و استان‌ها مدیر است.
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>کلید شیشه ای جدا چیست؟</template>

          کلید های شیشه ای کوچکی که زیر پیام های ربات ارسال می‌شود، هر کلید شامل متن
          دلخواه شما است و بیش از 5 هزار بار در روز در گروه‌ها پخش می‌شود و کاربران با
          کلیک روی این کلید ها وارد کانال شما می‌شوند. متن و لینک هر کلید بنا به سلیقه شما
          میتواند متفاوت و یا یکسان باشد میتوانید این کلید هارا به کانال، گروه و یا سایت
          خود لینک کنید. متن هر کلید باید شامل 4 تا 5 حرف باشد همچنین میتوان از شکلک ها در
          آن استفاده کرد. نمونه هایی از کلید شیشه ای را در زیر می‌بینید
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>بازدید معمولی یا ارسال معمولی چیست؟</template>

          یک بنر که شامل یک عکس (می‌توان به جای عکس از موارد دیگر مانند فایل، صوت و ...
          استفاده کرد) و متن زیر آن یا متن خالی می‌باشد که توسط شما تهیه می‌شود. این بنر
          در کانال
          <a href="https://t.me/mnrtab" target="_blank" class="font-bold text-sky-500">
            @mnrtab
          </a>
          قرار می‌گیرد و از آنجا توسط ربات ها در گروه ها ارسال می‌شوند.
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>ارسال بنر به طور مستقیم یا بازدید مستقیم چیست؟</template>

          این روش دقیقا مشابه به ارسال بنر می‌باشد با این تفاوت که بنر به طور مستقیم از
          کانال شما یا از طرف شما به گروه ها فورواد می‌شود و نام شما یا کانال شما بالای سر
          آن قرار میگیرد.
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>کلید شیشه‌ای زیر بنر چیست؟</template>

          کلید شیشه‌ای که زیر بنر قرار می‌گیرد و باعث جلب توجه بیشتر می‌شود. این کلید
          میتواند به کانال یا سایت شما لینک شود و متن دلخواه شما را داشته باشد.
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>چطور می‌توان از اجرای تبلیغات مطمئن بود؟</template>

          در هنگام پخش تبلیغات بازدیدی ربات برای شما گذارش ارسال می‌کند که تبلیغات در چه
          گروه هایی ارسال می‌شوند که میتوانید هنگام ارسال گزارش وارد گروه شوید و ببینید که
          تبلیغات شما در حال پخش است. البته باید در زمان کوتاهی این کار را انجام دهید زیرا
          به دلیل امنیت گروه ها از ربات های تبچی لینک هایی که برای شما ارسال می‌شوند بعد
          از مدتی منقضی می‌شوند
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>آیا تبلیغات کلید شیشه ای هم گزارش دارد؟</template>

          خیر. به دلیل حجم بالای ارسال گذارش ندارد اما میتوانید آمار آن را از داخل پنل
          مشاهده کنید
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>آیا تبلیغات ما در گروه های تکراری پخش می‌شود؟</template>

          تعداد گروه ها هموارده در حال زیاد شدن هستند اما با سرعت کم مثلا روزانه 20 گروه
          اضافه و چند گروه از لیست مدیریت ربات حذف می‌شوند. اما الگوریتم ارسال تبلیغ طوری
          طراحی شده که اولویت ارسال تبلیغ شما با گروه هایی هست که تبلیغ شما در آن ارسال
          نشده یا کمتر ارسال شده است
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>
            آیا ممکن است تبلیغاتی که در گروه ارسال می‌شود توسط ربات گروه یا ادمین ها پاک
            شوند؟
          </template>

          تبلیغات شما توسط ربات ادمین گروه ارسال می‌شود پس ربات های دیگر نمی‌توانند آنرا
          حذف کنند. و مجازات هایی مانند ارسال تبلیغ بیشتر برای گروه هایی که تبلیغ در آنها
          حذف می‌شود در نظر گرفته شده پس مدیران نیز معمولا تبلیغات ربات را حذف نمی‌کنند.
          البته ممکن است درصد کمی از مدیرات تبلیغات شما را حذف کنند.
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>آیا تبلیغات ما برای همیشه در گروه ها می‌ماند؟</template>

          خیر برای این که گروه های خلوت و کم جمعیت با تبلیغات ربات پر نشوند تبلیغات شما 5
          ساعت بعد از ارسال در هر گروه از گروها حذف می‌شود
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>
            آیا ممکن است تبلیغات فقط در گروه های یک استان یا گروه های مربوط به بانوان
            انجام شود؟
          </template>

          برای این کار باید تبلیغات هدفمند سفارش دهید
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>تبلیغات هدفمند چیست؟</template>

          در تبلیغات هدفمند می‌توانید در بین گروه ها جستجو کرده و گروه های هدف را پیدا
          کنید. البته این شامل همه گروه ها نمی‌شود و فقط شامل گروه هایی است که مدیر آنها
          در تبلیغات هدفمند شرکت کرده باشد
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>آیا میتوان برای گروه هم تبلیغ کرد؟</template>

          بله اما باید در نظر داشته باشید تبلیغ برای گروه باعث می‌شود ربات های خزنده از
          طریق لینک وارد گروه شما شوند و نظم گروه شما را به هم بریزند پس باید حواستان
          بیشتر به گروه خود باشد و یک ربات مدیر گروه قدرتمند داشته باشید. پیشنهاد ما ربات
          <a href="https://t.me/mnrg1bot" target="_blank" class="font-bold text-sky-500">
            @mnrg1bot
          </a>
          است
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>انتخاب دسته بندی تبلیغ برای چیست؟</template>

          با در نظر گرفتن دسته بندی انتخاب شده توسط شما ربات مدیریت میکند که تبلیغات با
          دسته بندی یکسان پشت سر هم ارسال نشوند. و همچنین بعضی از مدیران گروه ها ارسال یکی
          از دسته بندی ها را به گروه خود ممنوع کرده‌اند
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>آیا تبلیغات حتما بازده ممبر و فروش خواهد داشت؟</template>

          خیر. این که تبلیغ بازده داشته باشد به متغییر های زیادی بستگی دارد مانند کیفیت
          موضوعات یا محصولات کانال شما. مناسب بودن قیمت اجناس شما طراحی و سبک نوشتن بنر
          شما و همچنین بازار هدف شما. پس ممکن است شما از تبلیغ هیچ بازدهی نداشته باشید یا
          بازده خیلی خوبی داشته باشید. ما تمام تلاشمان را می‌کنیم که تبلیغات شفاف و با
          کیفیت پخش شوند
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>پیشنهاد شما برای سفارش چیست؟</template>

          اگر بار اول است که می‌خواهید تبلیغات سفارش دهید با تعرفه های کم و قیمت های پایین
          شروع کنید تا روش کار و بازده را بسنجید و در صورت مناسب نبودن بازده برای تبلیغ
          شما هزینه زیادی نکرده باشید اما قطعا هرچه بیشتر هزینه کنید و تبلیغات بیشتری
          سفارش دهید نتیجه بهتری خواهید گرفت پس اگر از نتیجه تبلیغ راضی بودید برای خرید
          های بعدی می‌توانید تبلیغات بیشتری خرید کنید
        </CommonQuestionCard>
      </div>
      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5"
        id="group_bot"
      >
        <div class="text-lg font-bold flex items-center gap-2">
          <img src="@/assets/images/icons8-bot-96.png" class="inline-block h-20" />
          <h2 class="text-lg font-bold">سوالات متداول ربات مدیریت گروه</h2>
        </div>

        <CommonQuestionCard class="mt-5">
          <template #title>ربات چه قابلیت هایی دارد؟</template>

          <p>
            تقریبا هر قابلیتی که شما نیاز دارید یا در ربات های دیگر دیده‌اید را این ربات
            دارد. برای نمونه تعدادی از قابلیت های این ربات را در زیر می‌بینید.
          </p>

          <ul class="list-inside list-disc mt-5">
            <li>ایجاد گروه VIP با پرداخت حق اشتراک</li>
            <li>دسته بندی کردن کاربران با تنظیمات مختلف</li>
            <li>تائید هویت کاربران مناسب برای ربات های تبچی cli</li>
            <li>فیلتر نام کاربران</li>
            <li>حذف ربات های مزاحم و پیام های آنها</li>
            <li>10 ها مورد قفل فایل و مدیا و ...</li>
            <li>حذف لینک ، یوزرنیم و ... با امکانات متفاوت</li>
            <li>دعوت اجباری برای ارسال پیام و لینک به صورت جدا</li>
            <li>قفل زماندار گروه دو زمانه</li>
            <li>عضویت اجباری در کانال یا گروه دیگر</li>
            <li>محدودیت ارسال پیام در روز و ساعت مشخص</li>
            <li>محدودیت تعداد کاراکتر پیام ها</li>
            <li>فیلتر پیشفرض و فیلتر دلخواه حرفه‌ای</li>
            <li>سیستم اخطار پیشرفته با قابلیت تنظیم تعداد اخطار و زمان اخطار</li>
            <li>مدیریت و تغییر تنظیمات گروهی چند گروه به طور هم زمان</li>
            <li>ارسال گزارش پیشرفته</li>
            <li>حذف پیام های ورود و خروج در گروه</li>
            <li>قابلیت کسب درامد از گروه به دو روش تبلیغات هدفمند و فروش اشتراک</li>
            <li>قابلیت شخصی سازی و تغییر تمام پیام های ربات</li>
            <li>جستجوی پیشرفته در بین اعضای گروه</li>
            <li>تنظیمات پیشرفته مدیران گروه</li>
            <li>تکرار یک پست در گروه</li>
            <li>
              اگر قابلیتی مد نظر شما هست که در ربات نیست میتونید به پشتیبانی ربات درخواست
              اضافه کردن آن را بدهید
              <a
                href="https://t.me/mnr73"
                target="_blank"
                class="font-bold text-sky-500"
                dir="ltr"
              >
                @mnr73
              </a>
            </li>
          </ul>
        </CommonQuestionCard>
        <CommonQuestionCard class="mt-5">
          <template #title>آیا ربات رایگان است؟</template>

          <p>
            بله تقریبا استفاده از تمام قابلیت های ربات به جز چند مورد خاص به طور رایگان و
            بدون محدودیت می‌باشد. و فقط برای حذف تبلیغات و یک سری از قابلیت های ویژه
            می‌توانید ربات را خریداری کنید
          </p>
        </CommonQuestionCard>
        <CommonQuestionCard class="mt-5">
          <template #title>چرا ربات تبلیغات ارسال می‌کند؟</template>

          <p>
            بر خلاف تصور عموم ربات های تلگرامی شامل هزینه های زیادی از جمله هزینه ماهانه
            سرور می‌باشد. و به علاوه هزینه های به روز رسانی، پشتیبانی و نگه داری. این
            هزینه ها باید از راه هایی تامین شود و یکی از این راه ها ارسال تبلیغات است. اگر
            نمیخواهید تبلیغات به گروه شما ارسال شود باید ربات را خریداری کنید.
          </p>
        </CommonQuestionCard>
        <CommonQuestionCard class="mt-5">
          <template #title>
            آیا ربات جلوی ربات های CLI و تبچی که در گروه با پیام های زشت و عکس های دختران
            فعالیت می‌کند را میگیرد؟
          </template>

          <p>
            بله. ربات قابلیت هایی برای جلوگیری از این موارد دارد مانند امنیت سراسری و
            تائید هویت کاربران.
          </p>
        </CommonQuestionCard>
        <CommonQuestionCard class="mt-5">
          <template #title>چگونه باید ربات را در گروه خود فعال کنم؟</template>

          <p>
            تنها کافیست یکی از ربات های زیر را در گروه خود ادمین کنید و ربات به صورت
            خودکار شروع به کار می‌کند.
          </p>

          <div class="flex flex-wrap my-5 gap-2">
            <a
              v-for="(bot, index) in group_bots"
              :key="index"
              :href="'https://t.me/' + bot"
              target="_blank"
              class="font-bold text-sky-500 p-3 border border-blueGray-200 rounded-md"
              dir="ltr"
            >
              @{{ bot }}
            </a>
          </div>

          <p>
            دقت کنید ربات های بالا هیچ تفاوتی با یک دیگر ندارند و تنها یکی از آنها در گروه
            ادمین باشد کافی است
          </p>
        </CommonQuestionCard>
        <CommonQuestionCard class="mt-5">
          <template #title>چگونه باید ربات را در گروه خود ادمین کنم؟</template>

          <p>
            برای ادمین کردن ربات در گروه باید به قسمت ادمین های گروه بروید. گزینه اضافه
            کردن ادمین جدید را بزنید و ID یا همان یوزرنیم رباتی که میخواهید را جستجو کرده
            و آن را ادمین کنید.
          </p>
        </CommonQuestionCard>
        <CommonQuestionCard class="mt-5">
          <template #title>چگونه باید ربات را تنظیم کنم؟</template>

          <p>
            بعد از ادمین کردن ربات در گروه به pv ربات بروید و استارت کنید. سپس گزینه رفتن
            به پنل را بزنید و از طریق لینکی که ربات برای شما ارسال می‌کند وارد پنل شوید و
            تنظیمات دلخواه خود را انجام دهید.
          </p>
        </CommonQuestionCard>
      </div>

      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5"
        id="channel_bot"
      >
        <div class="text-lg font-bold flex items-center gap-2">
          <img src="@/assets/images/icons8-bot-96-blue.png" class="inline-block h-20" />
          <h2 class="text-lg font-bold">سوالات متداول ربات مدیریت کانال</h2>
        </div>
        <CommonQuestionCard class="mt-5">
          <template #title>ربات چه قابلیت هایی دارد؟</template>

          <ul class="list-inside list-disc mt-5">
            <li>ایجاد کانال VIP با پرداخت حق اشتراک خودکار</li>
            <li>مدیریت چندین کانال</li>
            <li>مشخص کردن امضا برای کانال و قرار دادن خودکار امضا در زیر پست ها</li>
            <li>مشخص کردن امضا برای هر ادمین</li>
            <li>
              قابلیت قرار دادن کلید های شیشه ای و یا ویرایش آنها بعد و قبل از ارسال پست
            </li>
            <li>ارسال پست سر ساعت مشخص</li>
            <li>قرار دادن پست در صف های ارسال جداگانه</li>
            <li>قابلیت درخواست دعوت از کاربران کانال</li>
            <li>
              اگر قابلیتی مد نظر شما هست که در ربات نیست میتونید به پشتیبانی ربات درخواست
              اضافه کردن آن را بدهید
              <a
                href="https://t.me/mnr73"
                target="_blank"
                class="font-bold text-sky-500"
                dir="ltr"
              >
                @mnr73
              </a>
            </li>
          </ul>
        </CommonQuestionCard>
        <CommonQuestionCard class="mt-5">
          <template #title>آیا ربات رایگان است؟</template>

          <p>
            بله اما به کانال شما تبلیغات ارسال می‌کند. اگر بخواهید ربات به کانال شما
            تبلیغات ارسال نکند یا از بعضی قابلیت های ویژه استفاده کنید باید اشتراک ربات را
            خریداری کنید.
          </p>
        </CommonQuestionCard>
        <CommonQuestionCard class="mt-5">
          <template #title>چرا ربات تبلیغات ارسال می‌کند؟</template>

          <p>
            بر خلاف تصور عموم ربات های تلگرامی شامل هزینه های زیادی از جمله هزینه ماهانه
            سرور می‌باشد. و به علاوه هزینه های به روز رسانی، پشتیبانی و نگه داری. این
            هزینه ها باید از راه هایی تامین شود و یکی از این راه ها ارسال تبلیغات است. اگر
            نمیخواهید تبلیغات به کانال شما ارسال شود باید ربات را خریداری کنید.
          </p>
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>چگونه باید ربات را در کانال خود فعال کنم؟</template>

          <p>
            ربات را در کانال خود ادمین کنید. برای ادمین کردن به قسمت اضافه کردن ادمین در
            کانال خود بروید و
            <bdi>@mnrcbot</bdi>
            ربات را جستجو کنید
          </p>

          <div class="flex flex-wrap my-5 gap-2">
            <a
              href="https://t.me/mnrcbot"
              target="_blank"
              class="font-bold text-sky-500 p-3 border border-blueGray-200 rounded-md"
              dir="ltr"
            >
              @mnrcbot
            </a>
          </div>
        </CommonQuestionCard>

        <CommonQuestionCard class="mt-5">
          <template #title>چگونه باید ربات را تنظیم کنم؟</template>

          <p>
            بعد از ادمین کردن ربات در کانال به pv ربات بروید و استارت کنید. سپس گزینه رفتن
            به پنل را بزنید و از طریق لینکی که ربات برای شما ارسال می‌کند وارد پنل شوید و
            تنظیمات دلخواه خود را انجام دهید.
          </p>
        </CommonQuestionCard>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import CommonQuestionCard from "@/components/CommonQuestionCard.vue";
export default {
  components: { BodySimple, CommonQuestionCard },
  data() {
    return {
      group_bots: [
        "mnrG1bot",
        "mnrG1_2bot",
        "mnrG1_3bot",
        "mnrG1_4bot",
        "mnrG1_5bot",
        "mnrg1_6bot",
        "mnrG1_7bot",
        "mnrg1_8bot",
        "mnrg1_9bot",
        "mnrG1_10bot",
        "umnrbot",
        "mnrg1_abot",
        "mnrg1_bbot",
        "mnrg1_cbot",
      ],
    };
  },
};
</script>
